import { css } from '@emotion/core'
import styled from '@emotion/styled'

/* eslint-disable import/prefer-default-export */
export const SpeedTestIframe = styled.iframe(
  () => css`
    width: 100%;
    height: 650px;
  `
)
